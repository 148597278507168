// Modal.js
import React from 'react';
import '../CSS/Modal.css';

const Modal = ({ isOpen, onClose }) => {
  return (
    <>
      {isOpen && (
        <div className="modal-overlay" onClick={onClose}>
          <div className="modal-close"><button onClick={onClose} className='close-btn'>Close</button></div>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2 className='Soon'>Comming Soon!</h2>
            {/* <p>This is the modal content.</p> */}
            
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
