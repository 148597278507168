import "./App.css";
import MainPage from "./Pages/MainPage";

function App() {
  return <div >
   <MainPage></MainPage>



  </div>;
}

export default App;
