// Sidebar.js
import React, { useState } from 'react';
import '../CSS/Sidebar.css';

const Sidebar = ({ isOpen, onClose }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false); // Initially closed

  // Function to toggle the sidebar state
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  // Function to handle toggling sidebar state with the button within the sidebar
  const toggleupbtn = () => {
    setSidebarOpen(!sidebarOpen); // Toggle the sidebar state
  };

  // Function to handle closing the sidebar
  const handleCloseSidebar = () => {
    onClose(); // Call the onClose function passed as a prop
  };

  return (
    <div className={`sidebar ${isOpen ? '' : 'closed'}`}>
      {/* Button to toggle the sidebar state */}
     
      
      {/* Close button */}
      {isOpen && <button className="close-button" onClick={handleCloseSidebar}>Close Sidebar</button>}

      {/* Sidebar Content */}
      <div className="sidebar-content">Sidebar Content</div>
    </div>
  );
};

export default Sidebar;
