// MainPage.js
import React, { useState } from 'react';
import '../CSS/MainPage.css';
import Sidebar from '../Components/Sidebar';
import MainContent from '../Components/MainContent';

function MainPage() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  return (
    <div className="app">
      {/* Sidebar */}
      <Sidebar isOpen={sidebarOpen} onClose={closeSidebar} />

      {/* Main Content */}
      <MainContent
        sidebarOpen={sidebarOpen}
        toggleSidebar={toggleSidebar}
       // Pass the closeSidebar function to MainContent
      />
    </div>
  );
}

export default MainPage;
