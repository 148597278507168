// MainContent.js
import React, { useState } from 'react';
import axios from 'axios';
import '../CSS/MainContent.css';
import '../CSS/responsive.css';
import Modal from './Modal'; // Import the Modal component
import MyImage from './Send.png';

const MainContent = ({ sidebarOpen, toggleSidebar }) => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [prompt, setPrompt] = useState(''); // State to store the user input
  const [generatedText, setGeneratedText] = useState(''); // State to store the generated text

  const API_KEY = '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'; // Replace with your OpenAI API key

  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Function to handle input change
  const handleInputChange = (e) => {
    setPrompt(e.target.value);
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo', // Use the appropriate model
          messages: [
            { role: 'system', content: 'You are a helpful assistant.' },
            { role: 'user', content: prompt },
          ],
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${API_KEY}`,
          },
        }
      );
      setGeneratedText(response.data.choices[0].message.content);
    } catch (error) {
      console.error('Error fetching data from OpenAI API:', error);
      setGeneratedText('Error fetching data from OpenAI API ');
    }
  };

  return (
    <div className={`main ${sidebarOpen ? '' : 'fill-space'}`} >
      {/* Header */}
      <section className='container'>
      <div className="header">
        <button className="toggle-button" onClick={toggleSidebar}></button>

{/* 
        <div className="start-button">
          <button>Start</button>
          <span className="icon">⚙️</span>
        </div> */}


        {/* Share button */}
        <div className="share-button" onClick={openModal}></div>
      </div>

      {/* <div className='Text-genrated'></div> */}

      <h4>{generatedText}</h4>

      {/* Chat Interface */}
      <div className="chat-interface">
        {/* Chat History */}
        <div className="chat-history">
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              value={prompt}
              onChange={handleInputChange}
              placeholder="Type your message..."
            />
            <button className="submit-button" type="submit">
              <img src={MyImage} className='send-img' alt="Send" />
            </button>
          </form>
        </div>
      </div>
      </section>
      {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default MainContent;
